/* You can add global styles to this file, and also import other style files */
@import 'app/shared/fonts/byzantine';
@import 'app/shared/theme/colors';

html {
    font-size: 62.5%;
}

body {
    font-size: 1.6rem;
}


html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }


.font-byzantine {
    @include byzantine;
}

.button-primary {
    cursor: pointer;
    color: mat-contrast($materialPrimary, 700);
    background-color: mat-color($materialPrimary, 700);
    @include mat-elevation(4);
    border-radius: .4rem;
}

.mat-radio-input {
    display: none;
}

.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-label .mat-radio-container .mat-radio-outer-circle {
    border-color: mat-color($materialPrimary, 300, 1);
}


.mat-radio-label {
    .mat-radio-container {
        height: 1.6rem;
        width: 1.6rem;

        .mat-radio-outer-circle {
            height: 1.6rem;
            width: 1.6rem;
            border-color: mat-color($materialSecondary, 300, 1);            
        }

        .mat-radio-inner-circle {
            height: 1.6rem;
            width: 1.6rem;
            background-color: mat-color($materialPrimary, 300, 1);
        }
    }

    
    &:hover .mat-radio-outer-circle {
        border-color: mat-color($materialPrimary, 300, 1);
    }
}



.form-label {
    grid-area: name;
    display: grid;
    grid-template-areas:
        "label"
        "input";
    grid-template-rows: .2fr .8fr;
    grid-row-gap: .2rem;
    grid-template-columns: 1fr;

    .form-label-text {
        grid-area: label;
    }

    .form-label-input {
        transition-property: border, box-shadow;
        transition-duration: 250ms;
        transition-timing-function: linear;

        box-shadow: none;
        padding: .8rem;
        border-radius: .4rem;
        grid-area: input;
        outline: none;

        &:hover, &:focus {
            border: .2rem solid mat-color($materialPrimary, 200);
        }

        &:focus {
            @include mat-elevation(2, mat-color($materialPrimary, 50));
        }
    }
    
}


.background-image {
    width: 100%;
    height: 100%;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
}