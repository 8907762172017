@import '~@angular/material/theming';

// Include non-theme styles for core.
//@include mat-core();

/* For use in src/lib/core/theming/_palette.scss */
/* For use in src/lib/core/theming/_palette.scss */
$md-primary: (
    50 : #f0e1e0,
    100 : #dbb3b3,
    200 : #c38080,
    300 : #aa4d4d,
    400 : #982726,
    500 : #860100,
    600 : #7e0100,
    700 : #730100,
    800 : #690100,
    900 : #560000,
    A100 : #ff8888,
    A200 : #ff5555,
    A400 : #ff2222,
    A700 : #ff0808,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #ffffff,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #ffffff,
        A700 : #ffffff,
    )
);

$md-secondary: (
    50 : #faf5ee,
    100 : #f3e6d5,
    200 : #ebd5b9,
    300 : #e3c49d,
    400 : #ddb788,
    500 : #d7aa73,
    600 : #d3a36b,
    700 : #cd9960,
    800 : #c79056,
    900 : #be7f43,
    A100 : #ffffff,
    A200 : #ffeedf,
    A400 : #ffd3ac,
    A700 : #ffc692,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #000000,
        600 : #000000,
        700 : #000000,
        800 : #000000,
        900 : #000000,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);



$primaryColor: #990100;
$primaryColorLight: #d1432b;
$primaryColorDark: #650000;
$secondaryColor: #d7aa73;
$secondaryColorLight: #ffdca2;
$secondaryColorDark: #a47b46;


$materialPrimary: mat-palette($md-primary);
$materialSecondary: mat-palette($md-secondary);


$materialTheme: mat-light-theme($materialPrimary, $materialSecondary);

@include mat-radio-theme($materialTheme);